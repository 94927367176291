import throttle from "lodash.throttle";
import debounce from "lodash.debounce";

function setupBackToTop() {
  const visibleClassName = "back-to-top--visible";
  const backToTop = document.querySelector(".back-to-top");
  const sideContentElements = document.querySelectorAll(".side-component");
  const lastSideContent = sideContentElements[sideContentElements.length - 1];

  const getThreshold = () =>
    lastSideContent
      ? lastSideContent.getBoundingClientRect().bottom + window.scrollY
      : 1200;

  let backToTopThreshold = getThreshold();

  window.addEventListener(
    "scroll",
    throttle(() => {
      if (window.innerWidth <= 1010) {
        backToTop.classList.remove(visibleClassName);
        return;
      }
      if (window.scrollY > backToTopThreshold) {
        backToTop.classList.add(visibleClassName);
      } else {
        backToTop.classList.remove(visibleClassName);
      }
    }, 250)
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      if (window.innerWidth <= 1010) {
        return;
      }

      backToTopThreshold = getThreshold();
    }),
    1000
  );
}

export { setupBackToTop as default };
