class FormValidator {
  constructor(form, validators, errorMessages) {
    this.form = form;
    this.fields = Array.from(
      this.form.querySelectorAll("input:not([type='submit']), textarea")
    );
    this.validators = Object.assign({}, validators, FormValidator.validators);
    this.errorMessages = Object.assign(
      {},
      errorMessages,
      FormValidator.errorMessages
    );

    this.form.addEventListener("submit", event =>
      this._onFormSubmission(event)
    );
    this.form.setAttribute("novalidate", "novalidate");
  }

  _onFormSubmission(event) {
    const errors = this.validateFields(this.fields);

    this.fields.forEach(field => field.classList.remove("field-error"));
    Array.from(this.form.querySelectorAll(".error-message")).forEach(msg =>
      msg.remove()
    );

    if (errors.length > 0) {
      event.preventDefault();
      this.showErrors(errors);
    }
  }

  validateFields(fields) {
    let errors = [];

    fields.forEach(element => {
      const format = element.getAttribute("data-validate-format");

      if (element.hasAttribute("required") && element.value.trim() === "") {
        errors = [
          ...errors,
          {
            element,
            error: "required"
          }
        ];
      }

      if (format && !this.validateFormat(element.value, format)) {
        errors = [
          ...errors,
          {
            element,
            error: `format-${format}`
          }
        ];
      }
    });

    return errors;
  }

  validateFormat(value, format) {
    return this.validators[format](value);
  }

  showErrors(errors) {
    errors.forEach(error => {
      const errorMessage = document.createElement("p");
      errorMessage.classList.add("error-message");
      errorMessage.innerText = this.errorMessages[error.error];

      error.element.classList.add("field-error");
      error.element.insertAdjacentElement("afterend", errorMessage);
    });
  }
}

FormValidator.validators = {
  email: email => !!email.match(/.+@.+\..+/)
};
FormValidator.errorMessages = {
  required: "Este campo é obrigatório",
  "format-email": "Insira um email válido"
};

export { FormValidator as default };
